import { defineMessages, t } from '@sm/intl';
import { SignupButtonProps } from '@sm/webassets/ClientError/SignupButton';

const COPY = defineMessages({
  SIGN_UP_TEXT: {
    id: 'Errors.defaultSignupButtonProps.signUpText',
    defaultMessage: 'Want to create your own survey?',
    description: '[Type: Label][Vis.: med] - Sign up free button description text',
  },
  SIGN_UP_FREE: {
    id: 'Errors.defaultSignupButtonProps.signUpFree',
    defaultMessage: 'Sign Up FREE',
    description: '[Type: Label][Vis.: med] - Sign up free button',
  },
});

const defaultSignupButtonProps: SignupButtonProps = {
  buttonProps: {
    label: t(COPY.SIGN_UP_FREE),
    href: '/user/sign-up/?ut_source=404_page',
  },
  signUpText: t(COPY.SIGN_UP_TEXT),
};

export default defaultSignupButtonProps;
