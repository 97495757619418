import { defineMessages, t } from '@sm/intl';

const COPY = defineMessages({
  HOME: {
    id: 'Errors.defaultLinkProps.home',
    defaultMessage: 'Home',
    description: '[Type: Label][Vis.: med] - Home link in Error Message',
  },
  SITEMAP: {
    id: 'Errors.defaultLinkProps.sitemap',
    defaultMessage: 'Sitemap',
    description: '[Type: Label][Vis.: med] - Sitemap link in Error Message',
  },
  HELP_CENTER: {
    id: 'Errors.defaultLinkProps.helpCenter',
    defaultMessage: 'Help Center',
    description: '[Type: Label][Vis.: med] - Help Center link in Error Message',
  },
  TEMPLATES: {
    id: 'Errors.defaultLinkProps.templates',
    defaultMessage: 'Templates',
    description: '[Type: Label][Vis.: med] - Templates link in Error Message',
  },
  LEARN_MORE: {
    id: 'Errors.defaultLinkProps.learnMore',
    defaultMessage: 'Learn More',
    description: '[Type: Label][Vis.: med] - Learn More link in 404 Error Message',
  },
});

const defaultLinks = [
  {
    href: '/',
    label: t(COPY.HOME),
  },
  {
    href: '/mp/sitemap/',
    label: t(COPY.SITEMAP),
  },
  {
    href: 'https://help.surveymonkey.com/',
    label: t(COPY.HELP_CENTER),
  },
  {
    href: '/mp/survey-templates/',
    label: t(COPY.TEMPLATES),
  },
  {
    href: '/mp/take-a-tour/',
    label: t(COPY.LEARN_MORE),
  },
];

export default defaultLinks;
