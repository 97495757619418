import React from 'react';
import { FourHundredError as BaseFourHundredError } from '@sm/webassets/FourHundredError';
import { t, defineMessages } from '@sm/intl';
import defaultLinksProps from '../defaultLinkProps';
import defaultSignupButtonProps from '../defaultSignupButtonProps';

const COPY = defineMessages({
  SORRY_MESSAGE: {
    id: 'Errors.FourHundredError.sorryMessage',
    defaultMessage: "We're sorry",
    description: '[Type: Label][Vis.: med] - We are sorry message',
  },
  CANT_FIND_PAGE: {
    id: 'Errors.FourHundredError.cantFindPage',
    defaultMessage: "We can't find the page you're looking for.",
    description: '[Type: Label][Vis.: med] - Cant find page message',
  },
  CHECK_URL: {
    id: 'Errors.FourHundredError.checkURL',
    defaultMessage: "Please check the URL you entered to make sure it's spelled correctly.",
    description: '[Type: Label][Vis.: med] - check URL subtitle',
  },
  HELP_FIND_WAY: {
    id: 'Errors.FourHundredError.helpFindWay',
    defaultMessage: 'Still not sure how to get to the page you want? Maybe we can help you find your way:',
    description: '[Type: Label][Vis.: med] - Help find your way subtitle',
  },
});

export type Props = React.ComponentProps<typeof BaseFourHundredError>;

function FourHundredError({
  openingMessage = t(COPY.SORRY_MESSAGE),
  errorTitle = t(COPY.CANT_FIND_PAGE),
  titularElementTag = 'p',
  errorMessage = [t(COPY.CHECK_URL), t(COPY.HELP_FIND_WAY)],
  signupButtonProps = defaultSignupButtonProps,
  linksProps = { links: defaultLinksProps },
  showLinks = false,
}: Props): React.ReactElement {
  return (
    <BaseFourHundredError
      openingMessage={openingMessage}
      errorTitle={errorTitle}
      titularElementTag={titularElementTag}
      errorMessage={errorMessage}
      signupButtonProps={signupButtonProps}
      linksProps={linksProps}
      showLinks={showLinks}
      // disable fullscreen styling so that the component can by styled by the parent
      fullscreen={false}
    />
  );
}

export default FourHundredError;
