import React from 'react';
import { t, defineMessages } from '@sm/intl';
import { FiveHundredError as BaseFiveHundredError } from '@sm/webassets/FiveHundredError';
import defaultLinksProps from '../defaultLinkProps';

const DEFAULT_COPY = defineMessages({
  SORRY_MESSAGE: {
    id: 'Errors.FiveHundredError.SorryMessage',
    defaultMessage: "We're sorry",
    description: '[Type: Label][Vis.: med] - We are sorry message',
  },
  SOMETHING_WRONG: {
    id: 'Errors.FiveHundredError.SomethingWentWrong',
    defaultMessage: 'Something went wrong.',
    description: '[Type: Label][Vis.: med] - something went wrong subtitle',
  },
  FIVE_HUNDRED_MESSAGE: {
    id: 'Errors.FiveHundredError.Message',
    defaultMessage:
      "Sorry if you lost your place - we're working hard to get things up and running again. Thanks for your patience!",
    description: '[Type: Label][Vis.: med] - Five Hundred Error Message',
  },
});

export type Props = React.ComponentProps<typeof BaseFiveHundredError>;

function FiveHundredError({
  openingMessage = t(DEFAULT_COPY.SORRY_MESSAGE),
  errorTitle = t(DEFAULT_COPY.SOMETHING_WRONG),
  titularElementTag = 'p',
  errorMessage = t(DEFAULT_COPY.FIVE_HUNDRED_MESSAGE),
  linksProps = { links: defaultLinksProps },
}: Props): React.ReactElement {
  return (
    <BaseFiveHundredError
      openingMessage={openingMessage}
      errorTitle={errorTitle}
      titularElementTag={titularElementTag}
      errorMessage={errorMessage}
      linksProps={linksProps}
      // disable fullscreen styling so that the component can by styled by the parent
      fullscreen={false}
    />
  );
}
export default FiveHundredError;
