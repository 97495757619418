import React from 'react';
import { FourTenError as BaseFourTenError } from '@sm/webassets/FourTenError';
import { t, defineMessages } from '@sm/intl';
import defaultSignupButtonProps from '../defaultSignupButtonProps';

const DEFAULT_COPY = defineMessages({
  // Client Error
  OH_BANANAS: {
    id: 'Errors.FourTenError.ohBananas',
    defaultMessage: 'Oh bananas!',
    description: '[Type: Label][Vis.: med] - Oh bananas message',
  },
  TERMS_VIOLATION: {
    id: 'Errors.FourTenError.somethingWentWrong',
    defaultMessage: "This survey is currently closed due to a violation of SurveyMonkey's terms of use.",
    description: '[Type: Label][Vis.: med] - terms of use violation title',
  },
  // Primary Links
  HOME: {
    id: 'Errors.FourTenError.home',
    defaultMessage: 'SurveyMonkey Home',
    description: '[Type: Label][Vis.: med] - Home link in 410 Error Message',
  },
  SITEMAP: {
    id: 'Errors.FourTenError.sitemap',
    defaultMessage: 'Sitemap',
    description: '[Type: Label][Vis.: med] - Sitemap link in 410 Error Message',
  },
  LEARN_MORE: {
    id: 'Errors.FourTenError.howItWorks',
    defaultMessage: 'How It Works',
    description: '[Type: Label][Vis.: med] - How It Works link in 410 Error Message',
  },
  // Secondary Links
  TYPES: {
    id: 'Errors.FourTenError.types',
    defaultMessage: 'Survey Types',
    description: '[Type: Label][Vis.: med] - Survey Types link in 410 Error Message',
  },
  SATISFACTION_SURVEYS: {
    id: 'Errors.FourTenError.satisfactionSurveys',
    defaultMessage: 'Customer Satisfaction Surveys',
    description: '[Type: Label][Vis.: med] - Customer Satisfaction Surveys link in 410 Error Message',
  },
  EMPLOYEE_SURVEYS: {
    id: 'Errors.FourTenError.employeeSurveys',
    defaultMessage: 'Employee Surveys',
    description: '[Type: Label][Vis.: med] - Employee Surveys link in 410 Error Message',
  },
});

const defaultPrimaryLinks = {
  links: [
    {
      label: t(DEFAULT_COPY.HOME),
      href: '/',
    },
    {
      label: t(DEFAULT_COPY.SITEMAP),
      href: '/mp/sitemap/',
    },
    {
      label: t(DEFAULT_COPY.LEARN_MORE),
      href: '/mp/take-a-tour/',
    },
  ],
};

const defaultSecondaryLinks = {
  links: [
    {
      label: t(DEFAULT_COPY.TYPES),
      href: '/mp/survey-types/',
    },
    {
      label: t(DEFAULT_COPY.SATISFACTION_SURVEYS),
      href: '/mp/customer-satisfaction-surveys/',
    },
    {
      label: t(DEFAULT_COPY.EMPLOYEE_SURVEYS),
      href: '/mp/employee-surveys/',
    },
  ],
};

export type Props = React.ComponentProps<typeof BaseFourTenError>;

function FourTenError({
  openingMessage = t(DEFAULT_COPY.OH_BANANAS),
  errorTitle = t(DEFAULT_COPY.TERMS_VIOLATION),
  titularElementTag = 'p',
  signupButtonProps = defaultSignupButtonProps,
  primaryLinksProps = defaultPrimaryLinks,
  secondaryLinksProps = defaultSecondaryLinks,
}: Props): React.ReactElement {
  return (
    <BaseFourTenError
      openingMessage={openingMessage}
      errorTitle={errorTitle}
      titularElementTag={titularElementTag}
      signupButtonProps={signupButtonProps}
      primaryLinksProps={primaryLinksProps}
      secondaryLinksProps={secondaryLinksProps}
      // disable fullscreen styling so that the component can by styled by the parent
      fullscreen={false}
    />
  );
}

export default FourTenError;
