import React, { ReactElement } from 'react';
import BaseForbiddenError from '@sm/webassets/ForbiddenError';
import { t, defineMessages } from '@sm/intl';
import defaultLinksProps from '../defaultLinkProps';

const DEFAULT_COPY = defineMessages({
  SORRY_MESSAGE: {
    id: 'Errors.ForbiddenError.sorryMessage',
    defaultMessage: "We're sorry",
    description: '[Type: Label][Vis.: med] - We are sorry message',
  },
  PERMISSIONS: {
    id: 'Errors.ForbiddenError.permissions',
    defaultMessage: 'You do not have permission to see this page.',
    description: '[Type: Label][Vis.: med] - We are sorry message',
  },
  CHECK_URL: {
    id: 'Errors.ForbiddenError.checkURL',
    defaultMessage: "Please check the URL you entered to make sure it's spelled correctly.",
    description: '[Type: Label][Vis.: med] - check URL subtitle',
  },
  HELP_FIND_WAY: {
    id: 'Errors.ForbiddenError.helpFindWay',
    defaultMessage: 'Still not sure how to get to the page you want? Maybe we can help you find your way:',
    description: '[Type: Label][Vis.: med] - Help find your way subtitle',
  },
});

export type Props = React.ComponentProps<typeof BaseForbiddenError>;

const ForbiddenError = ({
  openingMessage = t(DEFAULT_COPY.SORRY_MESSAGE),
  errorTitle = t(DEFAULT_COPY.PERMISSIONS),
  titularElementTag = 'p',
  errorMessage = [t(DEFAULT_COPY.CHECK_URL), t(DEFAULT_COPY.HELP_FIND_WAY)],
  linksProps = { links: defaultLinksProps },
  showLinks = true,
}: Props): ReactElement => {
  return (
    <BaseForbiddenError
      openingMessage={openingMessage}
      errorTitle={errorTitle}
      titularElementTag={titularElementTag}
      errorMessage={errorMessage}
      linksProps={linksProps}
      showLinks={showLinks}
      // disable fullscreen styling so that the component can by styled by the parent
      fullscreen={false}
    />
  );
};

export default ForbiddenError;
